
export default [
    {
        path: '/tds',
        component: "__base",
        otherdata: { color: "ccompliance" },
        children: [
            {
                // module: "TDS",
                path: "ar",
                component: "ARList.vue",
                meta: { title: "AR Analysis List", right: "tds_ar_project" },
            },
            {
                // module: "TDS",
                path: "ar/view/:id",
                component: "ARView.vue",
                meta: { title: "AR Analysis Details", right: "tds_ar_analysis" },
            },
            {
                // module: "TDS",
                path: "ap",
                component: "APList.vue",
                meta: { title: "AP Analysis List", right: "tds_ap_project" },
            },
            {
                // module: "TDS",
                path: "ap/view/:id",
                component: "APView.vue",
                meta: { title: "AP Analysis Details", right: "tds_ap_analysis" },
            },
            {
                // module: "TDS",
                path: "monthly/:year/:month",
                component: "MonthlyRun.vue",
                meta: { title: "Monthly Run", right: "tds_monthly" },
            },
            {
                // module: "TDS",
                path: "monthly",
                component: "Monthly.vue",
                meta: { title: "Monthly Reports", right: "tds_monthly" },
            },
            // {
            //     // module: "TDS",
            //     path: "monthly/view",
            //     component: "MonthlyRun.vue",
            //     meta: { title: "Monthly Run" },
            // },
            {
                // module: "TDS",
                path: "vm",
                component: "MasterVendor.vue",
                meta: { title: "Vendor Master"},
            },
            {
                path: "filelist",
                component: "FileConfigList.vue",
                meta: { title: "File List", right: "tds_monthly", action: ["review", "add", "edit"] },
            },
            {
                path: "filelist/config/:configID?",
                component: "FileConfig.vue",
                meta: { title: "File config List", right: "tds_monthly", action: ["review", "add", "edit"]},
            },
            {
                path: "ipconfig",
                component: "InputParameterConfig.vue",
                meta: { title: "Input Parameters Config List", right: "tds_monthly", action: ["review", "add", "edit"] },
            },
            {
                // module: "TDS",
                path: "*",
                component: "",
            },
        ],
    },
]

